<script>
import api from '@/command/api'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'

export default {
  props: {
    businessIdArr: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ...api.command.getState(),
      selectedRowKeys: [],
    }
  },
  watch: {
    businessIdArr() {
      if (this.selectedRowKeys !== this.businessIdArr) {
        this.selectedRowKeys = this.businessIdArr
      }
    },
  },
  computed: {},
  mounted() {
    this.selectedRowKeys = this.businessIdArr
    api.command.getList.call(this, {
      url: '/goods/farmProductCombo/getBusinessList',
      params: {
        businessType: '1',
      },
      current: 1,
    })
  },
  methods: {
    sumbit() {
      this.selectedRowKeys = this.$refs.table.$refs.table.selectedRowKeys
      let selectedRowKeys = this.$refs.table.$refs.table.selectedRowKeys
      let selectedRows = this.$refs.table.$refs.table.selectedRows
      this.$emit('success', { selectedRowKeys, selectedRows })
    },
    getColumns() {
      return [
        {
          dataIndex: 'businessName',
          title: '酒店/景点名称',
          align: 'center',
        },
        {
          dataIndex: 'mainBodyName',
          title: '店铺名称',
          align: 'center',
        },
        {
          dataIndex: 'productCount',
          title: '商品数量',
          align: 'center',
        },
        {
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            return [
              {
                name: '选择',
                onClick: () => {
                  this.$emit('success', JSON.parse(JSON.stringify(records)))
                },
              },
            ]
          },
        },
      ]
    },
    getHeader() {
      return [
        {
          name: '商品类型',
          type: 'select',
          placeholder: '商品类型',
          key: 'businessType',
          defaultValue: '1',
          typeData: [
            {
              name: '景点门票',
              value: '1',
            },
            {
              name: '酒店民宿',
              value: '2',
            },
          ],
        },
        {
          name: '商品名称',
          type: 'input',
          placeholder: '商品名称',
          key: 'businessName',
        },
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        ref="table"
        showRowSelect={false}
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        records={this.records}
        rowKey="businessId"
        selectedRowKeys={this.selectedRowKeys}
        // rowSelection={{
        //   getCheckboxProps: (record) => {
        //     return {
        //       props: {
        //         disabled: this.businessIdArr.includes(record.businessId),
        //       },
        //     }
        //   },
        // }}
        resetDefaultData={{
          businessType: '1',
        }}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.template-card {
  min-height: 0;
}
/deep/ .header-box {
  margin-left: 0;
}
/deep/.ant-table-body {
  max-height: 500px;
}
</style>
